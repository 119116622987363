<template>
  <div class="locations-gmb-list">
    <screen-header
      class="locations-gmb-list__header"
      :title="
        $tc('locationsGmb.list.title', numberOfLoadedLocation, {
          brand: currentClient.name,
          count: numberOfLoadedLocation,
        })
      "
      :breadcrumbs="breadcrumbs"
      :is-loading="isLoading"
      :right-content="true"
      :isBeta="true"
    >
      <skeleton-icon v-if="isLoading" margin-left="16px" width="44px" height="44px" />
      <v-popover
        placement="bottom-end"
        :autoHide="true"
        container=".locations-gmb-list__header"
        popoverInnerClass="popover-inner no-overflow"
        v-else
      >
        <ui-button
          class="locations-gmb-list__header__cta"
          button="primary"
          variant="data"
          icon="store"
          :label="currentAccount.accountName"
          :mobile-toggle="true"
          v-if="accounts.length > 1 && currentAccount"
        />
        <template slot="popover">
          <div class="locations-gmb-list__header__popover">
            <ui-dropdown
              class="locations-gmb-list__header__popover__dropdown"
              id="dropdown-gmb-acounts"
              v-model="modelAccount"
              :options="accounts"
              :placeholder="$t('locations.id.header.frontOffice.placeholder')"
              :dropdown-label="$t('locations.id.header.frontOffice.label')"
              label="accountName"
              track-by="name"
              :show-label="true"
            />
          </div>
        </template>
      </v-popover>
    </screen-header>
    <screen-container :full-height="true">
      <screen-grid :full-height="true">
        <screen-card :full-height="true">
          <template v-slot:body>
            <div class="locations-gmb-list__list">
              <ui-data-list
                :items="locationsGmb"
                :is-loading="isLoading"
                :is-scrolling="false"
                :no-more-data="hasMoreLocationsToLoad"
                @click="goToLocation({ row: { name: $event.name } })"
              >
                <template v-slot:skeleton>
                  <div class="locations-gmb-list__list__content">
                    <skeleton-line height="18px" width="80%" />
                    <skeleton-line height="15px" width="50%" margin-top="3px" />
                  </div>
                  <skeleton-icon margin-left="16px" />
                </template>
                <template v-slot:item="slotProps">
                  <div class="locations-gmb-list__list__content">
                    <div class="locations-gmb-list__list__content__name">
                      {{ slotProps.item.locationName }}
                    </div>
                    <address class="locations-gmb-list__list__content__address">
                      {{ addressLines(slotProps.item) }}
                    </address>
                  </div>
                  <span
                    class="locations-gmb-list__list__status backoffice-icons"
                    :class="[`locations-gmb-list__list__status--${slotProps.item.isVerified}`]"
                  >
                    <template v-if="slotProps.item.isVerified === '1'"> success </template>
                    <template v-else>error</template>
                  </span>
                </template>
              </ui-data-list>
              <div class="locations-gmb-list__list__button">
                <skeleton-line v-if="isLoading" height="44px" width="170px" margin-top="45px" />
                <ui-button
                  :label="$t('gmbQa.buttons.loadMore')"
                  v-else-if="hasMoreLocationsToLoad"
                  @click="loadMoreGmbLocations"
                  button="cta"
                  variant="data"
                />
              </div>
            </div>
            <div
              class="locations-gmb-list__table"
              :class="{ 'locations-gmb-list__table--button-active': hasMoreLocationsToLoad }"
            >
              <ui-data-table
                :columns="columns"
                :rows="locationsGmb"
                :total-rows="numberOfLoadedLocation"
                :is-loading="isLoading"
                @onCellClick="goToLocation"
                :with-pagination="false"
              >
                <template slot="data-table-row" slot-scope="props">
                  <router-link
                    class="locations-gmb-list__table__link"
                    :to="{ name: 'LocationGmb', params: { name: props.row['name'] } }"
                  />
                  <span v-if="props.column.field === 'locationName'">
                    <div class="locations-gmb-list__table__block">
                      <div class="locations-gmb-list__table__block__content">
                        <div class="locations-gmb-list__table__block__content__name">
                          {{ props.row['locationName'] }}
                        </div>
                        <address class="locations-gmb-list__table__block__content__address">
                          {{ addressLines(props.row) }}
                        </address>
                      </div>
                    </div>
                  </span>
                  <span v-if="props.column.field === 'storeCode'">
                    {{ props.row['storeCode'] }}
                  </span>
                  <span v-else-if="props.column.field === 'placeId'">
                    {{ props.row['placeId'] }}
                  </span>
                  <span v-else-if="props.column.field === 'isVerified'">
                    <span
                      class="locations-gmb-list__table__status backoffice-icons"
                      :class="[`locations-gmb-list__table__status--${props.row['isVerified']}`]"
                    >
                      <template v-if="props.row['isVerified'] === '1'"> success </template>
                      <template v-else>error</template>
                    </span>
                  </span>
                  <span v-else-if="props.column.field === 'openInfo_status'">
                    {{ props.row['openInfo_status'] }}
                  </span>
                  <span v-else-if="props.column.field === 'isDuplicate'">
                    <span
                      class="locations-gmb-list__table__status-inverted backoffice-icons"
                      :class="[`locations-gmb-list__table__status-inverted--${props.row['isDuplicate']}`]"
                    >
                      <template v-if="props.row['isDuplicate'] === '1'"> success </template>
                      <template v-else>error</template>
                    </span>
                  </span>
                  <span v-else-if="props.column.field === 'select'">
                    <router-link :to="{ name: 'LocationGmb', params: { name: props.row['name'] } }">
                      <ui-button
                        class="locations-gmb-list__table__select"
                        button="primary"
                        icon="chevron_right"
                        :label="$t('locations.list.link')"
                        :iconOnly="true"
                      />
                    </router-link>
                  </span>
                </template>
              </ui-data-table>
              <div class="locations-gmb-list__table__button">
                <skeleton-line v-if="isLoading" height="44px" width="170px" margin-top="45px" />
                <ui-button
                  :label="$t('gmbQa.buttons.loadMore')"
                  v-else-if="hasMoreLocationsToLoad"
                  @click="loadMoreGmbLocations"
                  button="cta"
                  variant="data"
                />
              </div>
            </div>
          </template>
        </screen-card>
      </screen-grid>
    </screen-container>
  </div>
</template>

<script>
import { mapActions, mapState, mapGetters } from 'vuex'
import UiDataTable from '@/components/UI/DataTable.vue'
import UiDataList from '@/components/UI/DataList.vue'
import UiButton from '@/components/UI/Button.vue'
import UiDropdown from '@/components/UI/Dropdown.vue'
import ScreenHeader from '@/components/Screen/Header.vue'
import ScreenContainer from '@/components/Screen/Container.vue'
import ScreenGrid from '@/components/Screen/Grid.vue'
import ScreenCard from '@/components/Screen/Card.vue'
import SkeletonLine from '@/components/Skeleton/Line.vue'
import SkeletonIcon from '@/components/Skeleton/Icon.vue'
import { userRoles } from '@/config/permissions.config'

export default {
  name: 'LocationsGmbList',
  components: {
    UiButton,
    UiDataTable,
    UiDataList,
    UiDropdown,
    ScreenHeader,
    ScreenContainer,
    ScreenGrid,
    ScreenCard,
    SkeletonLine,
    SkeletonIcon,
  },
  data() {
    return {
      search: '',
      isLoading: false,
      userRoles,
    }
  },
  async created() {
    this.isLoading = true
    await this.getAccounts({})
    this.isLoading = false
    if (!this.$route.params.name && this.accounts?.length > 0) {
      this.$router.replace({
        ...this.$route,
        params: {
          ...this.$route.params,
          name: this.accounts[0].name,
        },
      })
    }
  },
  watch: {
    '$route.params.name': {
      immediate: true,
      async handler() {
        if (this.$route.params.name) {
          await this.loadGmbLocations()
        }
      },
    },
  },
  computed: {
    ...mapState({
      locationsGmb: state => state.gmb.locationsGmb.data,
      currentClient: state => state.user.currentClient,
      accounts: state => state.gmb.accounts,
    }),
    ...mapGetters({
      hasMoreLocationsToLoad: 'gmb/hasMoreLocationsToLoad',
      numberOfLoadedLocation: 'gmb/numberOfLoadedLocation',
    }),
    breadcrumbs() {
      return [
        {
          label: this.$t('locationsGmb.list.breadcrumb'),
          route: {
            name: 'LocationsGmb',
            params: {
              name: this.currentAccountName,
            },
          },
        },
      ]
    },
    columns() {
      return [
        {
          label: this.$t('locationsGmb.list.column.locationName'),
          field: 'locationName',
          sortable: false,
        },
        {
          label: this.$t('locationsGmb.list.column.storeCode'),
          field: 'storeCode',
          sortable: false,
        },
        {
          label: this.$t('locationsGmb.list.column.placeId'),
          field: 'placeId',
          sortable: false,
        },
        {
          label: this.$t('locationsGmb.list.column.status'),
          field: 'isVerified',
          sortable: false,
        },
        {
          label: this.$t('locationsGmb.list.column.isDuplicate'),
          field: 'isDuplicate',
          sortable: false,
        },
        {
          label: this.$t('locationsGmb.list.column.openInfo'),
          field: 'openInfo_status',
          sortable: false,
        },
      ]
    },
    currentAccount() {
      return this.accounts.find(account => account.name === this.currentAccountName)
    },
    currentAccountName() {
      return this.$route.params.name
    },
    modelAccount: {
      get() {
        return this.currentAccountName
      },
      async set({ name }) {
        this.$router.push({ ...this.$route, params: { ...this.$route.params, name } })
      },
    },
  },
  methods: {
    ...mapActions({
      getAccounts: 'gmb/getAccounts',
      loadLocations: 'gmb/loadLocations',
      loadMoreLocations: 'gmb/loadMoreLocations',
    }),
    goToLocation(e) {
      this.$router.push({ name: 'LocationGmb', params: { name: e.row.name } })
    },
    async loadGmbLocations() {
      this.isLoading = true
      try {
        await this.loadLocations({ accountName: this.currentAccountName })
      } finally {
        this.isLoading = false
      }
    },
    async loadMoreGmbLocations() {
      this.isLoading = true
      try {
        await this.loadMoreLocations()
      } finally {
        this.isLoading = false
      }
    },
    addLocation() {
      this.$router.push({ name: 'LocationCreate' })
    },
    addressLines(item) {
      let address = ''

      if (item.address_addressLines?.length > 0) {
        address += item.address_addressLines[0]
      }

      if (item.address_postalCode) {
        address += `, ${item.address_postalCode}`
      }

      if (item.address_locality) {
        address += ` ${item.address_locality}`
      }
      return address
    },
  },
}
</script>

<style lang="scss" scoped>
.locations-gmb-list {
  padding-bottom: $button-min-height + $gutter-mobile * 2;

  @media (min-width: $screen-sm) {
    padding-bottom: 0;
  }
  &__header {
    &__cta {
      margin-left: $gutter-mobile;

      @media (min-width: $screen-sm) {
        margin-left: $gutter-tablet;
      }

      @media (min-width: $screen-xl) {
        margin-left: $gutter-desktop;
      }
    }
    &__popover {
      padding: $gutter-mobile;
      min-width: 280px;

      @media (min-width: $screen-sm) {
        padding: $gutter-tablet;
        min-width: 340px;
      }
      &__dropdown {
        margin-top: $gutter-mobile;

        @media (min-width: $screen-sm) {
          margin-top: $gutter-tablet;
        }
        &:first-child {
          margin-top: 0;
        }
      }
    }
  }
  &__list {
    position: relative;
    margin-bottom: -16px;

    @media (min-width: $screen-sm) {
      margin: 0 (-$gutter-tablet) (-$gutter-tablet);
    }

    @media (min-width: $screen-md) {
      display: none;
    }
    &__select {
      margin-left: 7px;
      width: auto;
      height: auto;
      color: $grey-french;
    }
    &__logo {
      margin-right: $gutter-mobile;
      width: 36px;
      height: 36px;

      @media (min-width: $screen-sm) {
        margin-right: $gutter-tablet;
      }
    }
    &__content {
      flex: 1;
      &__name {
        font-weight: 600;
      }
      &__address {
        @include text-ellipsis(1, 12px);

        margin-top: 3px;
        font-size: $font-size-sm;
        font-style: normal;

        @media (min-width: $screen-sm) {
          @include text-ellipsis(1, 14px);

          margin-right: $gutter-tablet;
          font-size: $font-size-default;
        }
      }
    }
    &__status {
      margin-left: $gutter-mobile;
      color: map-get($generic-color-variants, 'error');
      &--1,
      &--10,
      &--20 {
        color: map-get($generic-color-variants, 'success');
      }
    }

    &__button {
      display: flex;
      justify-content: center;
      padding: 1rem;
    }
  }
  &__table {
    display: none;

    @media (min-width: $screen-md) {
      display: block;
      height: 100%;
    }

    &--button-active {
      height: calc(100% - #{$input-min-height});
    }

    &__link {
      @include full-link;
    }
    &__select {
      width: auto;
      height: auto;
      color: $grey-french;
    }

    &__button {
      display: flex;
      justify-content: center;
    }

    &__status {
      color: map-get($generic-color-variants, 'error');
      &--1,
      &--10,
      &--20 {
        color: map-get($generic-color-variants, 'success');
      }
    }
    &__status-inverted {
      color: map-get($generic-color-variants, 'success');
      &--1,
      &--10,
      &--20 {
        color: map-get($generic-color-variants, 'error');
      }
    }
    &__block {
      display: flex;
      align-items: center;
      &__logo {
        width: 36px;
        height: 36px;

        @media (min-width: $screen-md) {
          margin-right: $gutter-tablet;
        }
      }
      &__content {
        display: flex;
        flex-direction: column;
        &__name {
          font-weight: 600;
        }
        &__address {
          @include text-ellipsis(1, 14px);

          margin-top: 3px;
          font-size: $font-size-default;
          font-style: normal;
        }
      }
      &__icon {
        border: 1px solid;
        border-radius: 50%;
        padding: 3px;
        color: map-get($generic-color-variants, 'influence');
        &:first-child {
          margin-right: 8px;
        }
        &--inactive {
          color: $generic-color-bg-disabled;
        }
      }
    }
  }
}
</style>
